import { Header, ReviewItem, Title } from "../components/";
import styles from "./Reviews.module.css";
import { useEffect, useState } from "react";
import {
  FacebookResponse,
  IndividualFacebookResponse,
  StoredResponse,
} from "../types/FacebookTypes";

export const Reviews = () => {
  const [reviews, setReviews] = useState<IndividualFacebookResponse[] | null>(
    null,
  );
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);

  const accessCode = process.env.REACT_APP_HYDROCLEAN_FB_ACCESS_TOKEN;

  const requestString = `https://graph.facebook.com/v20.0/104748565334108/ratings?fields=created_time%2Crecommendation_type%2Creview_text%2Crating&access_token=${accessCode}`;

  const storageItem = localStorage.getItem("reviews");
  const savedReviews: StoredResponse = storageItem
    ? JSON.parse(storageItem)
    : null;

  useEffect(() => {
    if (storageItem && new Date(savedReviews.date) >= todayDate) {
      console.log("Getting local reviews");
      setReviews(savedReviews.response.data);
    } else {
      fetch(requestString)
        .then((response) => {
          return response;
        })
        .then(async (json) => {
          const obj: FacebookResponse = await json.json();
          if (json.status !== 200) {
            console.error(
              `Not able to fetch reviews from Facebook. Status Code: ${json.status}\nResponse:`,
                obj,
            );
            return;
          } else {
            const storedObj: StoredResponse = {
              response: obj,
              date: todayDate.toString(),
            };
            setReviews(storedObj.response.data);
            localStorage.setItem("reviews", JSON.stringify(storedObj));
          }
        })
        .catch((error) => console.error(error));
    }
  }, []);

  const getReviewsElement = () => {
    if (reviews) {
      return reviews.map((r) => {
        const date = new Date(r.created_time);
        const prettyDate = `${date.getDate()}/${
          date.getMonth() + 1
        }/${date.getFullYear()}`;
        return (
          <div className={styles.review} key={`${r.created_time}`}>
            <li>
              <ReviewItem
                // "User" used como nome por enquanto como placeholder, precisa de aprovação para prod do facebook para obter nomes
                name="User"
                date={prettyDate}
                stars={5}
                comment={r.review_text}
                // Também usando fotos de perfil de placeholder, veja ReviewItem para detalhar sobre elas
                profilePicture=""
              />
            </li>
          </div>
        );
      });
    } else {
      return <div className={styles.Loading}></div>;
    }
  };

  return (
    <div className={styles.reviewsBackground}>
      <Header linksStyle="" />
      <Title text={"What Our Customers Have to Say About Us"} size={28} />
      <div className={styles.reviewsWrapper}>{getReviewsElement()}</div>
    </div>
  );
};