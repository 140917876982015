import { useEffect, useRef, useState } from "react";
import PhotoAlbum from "react-photo-album";

import styles from "./ImageGallery.module.css";
import { Header, Title } from "../components";

type FacebookGalleryResponse = {
  data: {
    full_picture: string;
    id: string;
    created_time: string;
    message: string;
    permalink_url: string;
  }[];
};

type PhotoGalleryItem = {
  src: string;
  width: number;
  height: number;
  message: string;
};

type StoredGalleryResponse = {
  response: FacebookGalleryResponse;
  date: string;
};

export const ImageGallery = () => {
  const [photos, setPhotos] = useState<PhotoGalleryItem[] | null>(null);
  const [inModal, setInModal] = useState(false);
  const [modalSrc, setModalSrc] = useState("");
  const [modalText, setModalText] = useState("");
  const modalRef = useRef<HTMLDivElement | null>(null);

  const accessCode = process.env.REACT_APP_HYDROCLEAN_FB_ACCESS_TOKEN;
  const requestString = `https://graph.facebook.com/v20.0/104748565334108/feed?limit=100&fields=full_picture%2Ccreated_time%2Cmessage%2Cpermalink_url%2Cattachments&access_token=${accessCode}`;

  const aspectRatio = (height: number, width: number) => {
    if (height > width) {
      return { width: 1, height: height / width };
    } else {
      return { height: 1, width: width / height };
    }
  };

  const getLoadImage = () => {
    return (src: string, message: string) => {
      return new Promise<PhotoGalleryItem>((resolve) => {
        const image = new Image();
        image.src = src;
        image.onload = () => {
          const aspect = aspectRatio(image.height, image.width);
          resolve({
            src,
            width: aspect.width,
            height: aspect.height,
            message: message,
          });
        };
      });
    };
  };

  useEffect(() => {
    const todayDate = new Date();
    const storageItem = localStorage.getItem("gallery");
    const savedGallery: StoredGalleryResponse | null = storageItem
      ? JSON.parse(storageItem)
      : null;

    const isSameDay = (date1: Date, date2: Date) => {
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
      );
    };

    if (savedGallery && isSameDay(new Date(savedGallery.date), todayDate)) {
      console.log("Getting local gallery");
      const loadImage = getLoadImage();

      const loadAllImages = async () => {
        console.log(`response: `, savedGallery.response.data)
        const filtered = savedGallery.response.data.filter(
          (d) => d.full_picture !== undefined,
        );
        console.log(filtered);
        const images = await Promise.all(
          filtered.map((data) => loadImage(data.full_picture, data.message)),
        );
        setPhotos(images);
      };

      loadAllImages();
    } else {
      fetch(requestString)
        .then((response) => response.json())
        .then((res: FacebookGalleryResponse) => {
          const loadImage = (src: string, message: string) => {
            return new Promise<PhotoGalleryItem>((resolve) => {
              const image = new Image();
              image.src = src;
              image.onload = () => {
                const aspect = aspectRatio(image.height, image.width);
                resolve({
                  src,
                  width: aspect.width,
                  height: aspect.height,
                  message: message,
                });
              };
            });
          };

          const loadAllImages = async () => {
            const filtered = res.data.filter(
              (d) => d.full_picture !== undefined,
            );
            console.log(filtered);
            const images = await Promise.all(
              filtered.map((data) =>
                loadImage(data.full_picture, data.message),
              ),
            );
            setPhotos(images);
          };

          const storedObj: StoredGalleryResponse = {
            response: res,
            date: todayDate.toString(),
          };
          localStorage.setItem("gallery", JSON.stringify(storedObj));
          loadAllImages();
        })
        .catch((err) => console.error(err));
    }
  }, [requestString]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setInModal(false);
        setModalSrc("");
      }
    };

    if (inModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inModal]);

  const getGallery = () => {
    if (photos && !inModal) {
      // Photo album part
      return (
        <PhotoAlbum
          photos={photos}
          layout="rows"
          onClick={({ photo }) => {
            setModalSrc(photo.src);
            setInModal(true);
            setModalText(photo.message);
          }}
        />
      );
      // Modal part
    } else if (inModal) {
      return (
        <div
          className={styles.modalWrapper}
          ref={modalRef}
          onClick={() => {
            setInModal(false);
            setModalSrc("");
            setModalText("");
          }}
        >
          <img src={modalSrc} className={styles.modalImage} />
          <div className={styles.modalMessage}>{modalText}</div>
        </div>
      );
    } else {
      return <div className={styles.loading}></div>;
    }
  };

  return (
    <div className={styles.galleryBackground}> {/* Adicione esta linha */}
      <Header linksStyle="" />
      <div className={styles.main}>
        <Title text={"Gallery"} size={28} />
        <div className={styles.album}>{getGallery()}</div>
      </div>
    </div>
  );
};