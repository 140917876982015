import { Header, Title } from "../components";

import profilePic from "../assets/images/mauro-profile.png";
import phone from "../assets/icons/phone.svg";
import email from "../assets/icons/email.svg";
import facebook from "../assets/icons/facebook.svg";

import styles from "./Contact.module.css";

export const Contact = () => {
  return (
    <div className={styles.contactBackground}>  {/* Adicione esta linha */}
      <Header linksStyle="" />
      <Title text={"Book a HydroClean service today!"} size={28} />
      <div className={styles.main}>
        <div className={styles.profile}>
          <img src={profilePic} alt="profile" />
        </div>
        <div className={styles.details}>
          <div className={styles.name}>Mauro Massari</div>
          <div className={styles.info}>Heavy Duty Diesel Mechanic</div>
          <div className={styles.info}>Auto Electrician</div>
          <div className={styles.insured}>Australian-Insured Business</div>
          <div className={styles.detail}>
            <img src={phone} alt={"phone"} className={styles.icon} />{" "}
            <a href="sms:0413119343;?body=Suburb:%0D%0AEngine size (in litres):%0D%0AFuel type:">
              SMS - 0413119343
            </a>
          </div>
          <div className={styles.email}>
            <img src={email} alt={"email"} className={styles.icon} />
            <a href="mailto:mauromassaribr@gmail.com">
              mauromassaribr@gmail.com
            </a>
          </div>
          <div className={styles.detail}>
            <img src={facebook} alt={"facebook"} className={styles.icon} />
            <a
              href="https://www.facebook.com/profile.php?id=100075687891593"
              target="_blank"
              rel="noreferrer"
            >
              HydroClean on Facebook
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};