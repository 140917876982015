import { HText, Paragraph } from "../components";

import styles from "./About.module.css";

export const About = () => {
  return (
    <div className={styles.bodyMain}>
    <div className={styles.main}>
      <HText text={"About HydroClean"} />
      <Paragraph
        text={`Welcome to HydroClean, the gateway to a greener and more efficient engine performance! At HydroClean, we utilise our patented Hydrogen Engine Cleaning systems to enhance the performance of your engine, boost fuel efficiency, and significantly reduce harmful emissions that contribute to environmental pollution.`}
      />
      <Paragraph
        text={`Our innovative hydrogen generation technology creates hydrogen on-demand, offering a 100% toxic and chemical-free solution to clean petrol, diesel, LPG, and 2-stroke engines for cars, motorbikes, trucks, boats, and planes. Unlike traditional engine cleaning methods that rely on harmful chemicals, our system uses green and clean hydrogen generated from water, ensuring a superior cleaning experience that is eco-friendly.`}
      />
      <HText text={"Increased Engine Performance"} />
      <Paragraph
        text={
          "The core of our service lies in removing carbon and other deposits from the inside of your engine using active hydrogen. A cleaner engine means less friction among the moving parts, which significantly improves engine performance concerning both power and acceleration."
        }
      />
      <HText text={"Improved Fuel Efficiency"} />
      <Paragraph
        text={`Our service treatment is known to enhance fuel efficiency by meticulously removing deposits in the engine cylinders and other crucial engine components like turbochargers, exhaust, emissions systems, and electronic sensors. Cleaner engine sensors deliver improved data to the engine computers, boosting both engine and vehicle performance.`}
      />

      <HText text={"Cleaner Engine, Lower Emissions"} />
      <Paragraph
        text={`The ripple effect of a cleaner engine extends to lowered engine emissions, as many emissions occur when harmful engine deposits are burnt and released into the exhaust with the exhaust gases. Our system effectively cleans the exhaust, turbo, and emissions systems of deposits, making a substantial contribution to a cleaner environment.`}
      />

      <HText text={"No Harmful Chemicals"} />

      <Paragraph
        text={`Our patented Hydrogen Generator creates active Hydrogen gas, on-demand, for cleaning combustion engines, resulting in increased engine performance, lower harmful emissions, and improved fuel efficiency, all without the use of harmful toxic chemicals, just clean, green Hydrogen. No liquids added to your engine, only Hydrogen gas.`}
      />
    </div>
    </div>
  );
};
