import Review from "../types/Review";

import styles from "./ReviewItem.module.css";

import starSVG from "../assets/icons/star.svg";
import user1 from "../assets/icons/user1.svg";
import user2 from "../assets/icons/user2.svg";
import user3 from "../assets/icons/user3.svg";
import user4 from "../assets/icons/user4.svg";
import { Link } from "react-router-dom";

export const ReviewItem = (review: Review) => {
  const starImg = <img src={starSVG} alt="star" />;

  // Using placeholder images for now
  const userImgs = [
    <img src={user1} alt="placeholder user" />,
    <img src={user2} alt="placeholder user" />,
    <img src={user3} alt="placeholder user" />,
    <img src={user4} alt="placeholder user" />,
  ];

  const imgChoice = userImgs[Math.floor(Math.random() * userImgs.length)];

  return (
    <div className={styles.review}>
      <Link
        className={styles.link}
        to="https://www.facebook.com/profile.php?id=100075687891593&sk=reviews"
      >
        <div className={styles.profilePic}>{imgChoice}</div>
        <div>{review.name}</div>
        <div>
          {starImg}
          {starImg}
          {starImg}
          {starImg}
          {starImg}
        </div>
        <div>{review.date}</div>
        <div>{review.comment}</div>
      </Link>
    </div>
  );
};
