import styles from "./Title.module.css";

type TitleProps = {
  text: string;
  size: number;
};


export const Title = ({ text, size }: TitleProps) => {
  const style = {
    fontSize: `${size}px`,
  };

  return (
    <div className={styles.text} style={style}>
      {text}
    </div>
  );
};
