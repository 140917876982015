import React from "react";
import { Analytics } from "@vercel/analytics/react";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  Root,
  Home,
  About,
  Reviews,
  ImageGallery,
  Contact,
  PrivacyPolicy,
} from "./pages";

import styles from "./App.module.css";
import ScrollToTop from "./utils/scrollToTop";

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Root />}>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/gallery" element={<ImageGallery />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <Analytics />
    </>
  );
}

export default App;
