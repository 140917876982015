import { useNavigate } from "react-router-dom";
import { HText, Header, Paragraph, Title } from "../components";
import { SubTitle } from "../components/SubTitle"; // Certifique-se de que está importando SubTitle corretamente

import engine from "../assets/icons/engine.svg";
import fuel from "../assets/icons/fuel.svg";
import emissions from "../assets/icons/emissions.svg";

import styles from "./Home.module.css";

export const Home = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contact");
  };

  return (
    <>
    <Header linksStyle="main" />
    <div className={styles.main}>
      <div className={styles.bodyHead}>
        <div className={styles.bodyTitle}>
            <Title text="Your Gateway to Enhanced Engine Performance!" size={30} />
        </div>

        <button className={styles.ctaButtonHead} onClick={handleClick}>
          Book Now
        </button>
      </div>
      <div className={styles.bodyContainer}>
        <div className={styles.bodyContent}>
        <div className={styles.bodyTopicTitle}>
          <HText text="Key Benefits" />
        </div>
        <div className={styles.bodyTopicSub}>
          <Paragraph text="Our innovative Hydrogen Engine Cleaning system offers a host of benefits, including:" />
        </div>
        <div className={styles.bodyTopicContent}>
          <ul className={styles.benefitsList}>
            <li className={styles.benefit}>
              <div className={styles.top}>
                <img src={engine} alt="engine" className={styles.icon} />
                Increased Engine Performance:
              </div>
              <div className={styles.bottom}>
                Discover the true potential of your engine with our active Hydrogen
                cleaning system that removes harmful deposits, reducing friction and
                enhancing power and acceleration.
              </div>
            </li>
            <li className={styles.benefit}>
              <div className={styles.top}>
                <img src={fuel} alt="fuel efficiency" className={styles.icon} />
                Improved Fuel Efficiency:
              </div>
              <div className={styles.bottom}>
                Enjoy a smoother ride and save on fuel costs as our service
                treatment meticulously cleans engine cylinders and other crucial
                components, promoting better fuel efficiency.
              </div>
            </li>
            <li className={styles.benefit}>
              <div className={styles.top}>
                <img
                  src={emissions}
                  alt="lower emissions"
                  className={styles.icon}
                />
                Lower Emissions:
              </div>
              <div className={styles.bottom}>
                Take a step towards a cleaner environment with our service that
                significantly lowers engine emissions by effectively cleaning
                exhaust, turbo, and emissions systems of deposits.
              </div>
            </li>
          </ul>
        </div>
      
      <HText text="Experience Unmatched Engine Cleaning with Our Patented Hydrogen Technology!" />
      <Paragraph text="At HydroClean, we believe in delivering a greener, cleaner, and more efficient engine performance. Our patented Hydrogen Engine Cleaning system is designed to revitalize your engine, improve fuel efficiency, and reduce harmful emissions. Say goodbye to traditional, chemical-laden engine cleaning methods and embrace the future with HydroClean's eco-friendly solution!" />
      <div onClick={() => navigate("/about")} className={styles.learnMore}>
        Learn more
      </div>
      <HText text={"Book now!"} />
      <Paragraph
        text={"Ready to experience the HydroClean difference? Book your Hydrogen Engine Cleaning service today and drive towards a greener future!"}
      />
      <button className={styles.ctaButtonFooter} onClick={handleClick}>Book Now</button>
    </div>
    </div>
    </div>
    </>
  );
};