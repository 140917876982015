import { Link, NavLink } from "react-router-dom";
import headEmail from "../assets/icons/email.svg";
import styles from "./Header.module.css";
import fbk from "../assets/icons/facebooklogo.svg";
import logo from "../assets/images/logo3.png";
import touchPhone from "../assets/icons/touchphone.svg";
type headerprops = {
  linksStyle: string
}

export const Header = ({linksStyle}:headerprops) => {
  let headerStyle = styles.links;
  if (linksStyle !== "main") {
    headerStyle = styles.secondaryPages
  }
  return (
    <>
      <div className={styles.navHead}>
        <a
          href="https://www.facebook.com/profile.php?id=100075687891593"
          className={styles.fbkHeadContainer}
        >
          <button className={styles.facebook}>
            <img className={styles.fbkLogo} src={fbk} alt="Facebook Logo" />
          </button>
        </a>
        <NavLink to="/contact" className={styles.quoteButton}>
          <button className={styles.quote}>
            <p className={styles.buttonName}>GET A FREE QUOTE</p>
          </button>
        </NavLink>
        <div className={styles.logoHead}>
          <img className={styles.logo} src={logo} alt="logo" />
        </div>
        <a
          className={styles.smsContainer}
          href="sms:0413119343;?body=Suburb:%0D%0AEngine size (in litres):%0D%0AFuel type:"
        >
          <img className={styles.headPhone} src={touchPhone} alt="phone" />
          SMS - 0413 119 343
        </a>
        <a href="mailto:mauromassaribr@gmail.com" className={styles.emailHead}>
          <img className={styles.emailIcon} src={headEmail} alt="Email Icon" />
          mauromassaribr@gmail.com
        </a>
      </div>
      <nav className={headerStyle}>
        <NavLink to="/" className={`${styles.link} ${styles.buttonUnderline}`}>
          Home
        </NavLink>
        <NavLink
          to="/reviews"
          className={`${styles.link} ${styles.buttonUnderline}`}
        >
          Reviews
        </NavLink>
        <NavLink
          to="/contact"
          className={`${styles.link} ${styles.buttonUnderline}`}
        >
          Contact
        </NavLink>
        <NavLink
          to="/gallery"
          className={`${styles.link} ${styles.buttonUnderline}`}
        >
          Gallery
        </NavLink>
      </nav>
    </>
  );
};
