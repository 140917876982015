import { HText, Paragraph, Title } from "../components";

export const PrivacyPolicy = () => {
  return (
    <div>
      <Title text={"Privacy Policy"} size={28} />
      <Paragraph
        text={
          "HydroClean Engine Cleaning is committed to providing quality services to you and this policy outlines our ongoing obligations to you in respect of how we manage your Personal Information.\n" +
          "We have adopted the Australian Privacy Principles (APPs) contained in the Privacy Act 1988 (Cth) (the Privacy Act). The NPPs govern the way in which we collect, use, disclose, store, secure and dispose of your Personal Information.\n" +
          "A copy of the Australian Privacy Principles may be obtained from the website of The Office of the Australian Information Commissioner at https://www.oaic.gov.au/.\n"
        }
      />
      <HText text={"What is Personal Information and why do we collect it?"} />
      <Paragraph
        text={
          "Personal Information is information or an opinion that identifies an individual. Examples of Personal Information we collect includes names, addresses, email addresses, phone and facsimile numbers.\n" +
          "This Personal Information is via our website www.hydrocleanec.com.au and from cookies. We don’t guarantee website links or policy of authorised third parties.\n" +
          "We collect your Personal Information for the primary purpose of providing our services to you, providing information to our clients and marketing. We may also use your Personal Information for secondary purposes closely related to the primary purpose, in circumstances where you would reasonably expect such use or disclosure. You may unsubscribe from our mailing/marketing lists at any time by contacting us in writing.\n" +
          "When we collect Personal Information we will, where appropriate and where possible, explain to you why we are collecting the information and how we plan to use it.\n"
        }
      />
      <HText text={"Sensitive Information"} />
      <Paragraph
        text={
          "Sensitive information is defined in the Privacy Act to include information or opinion about such things as an individual's racial or ethnic origin, political opinions, membership of a political association, religious or philosophical beliefs, membership of a trade union or other professional body, criminal record or health information.\n" +
          "Sensitive information will be used by us only:"
        }
      />
      <Paragraph text={"For the primary purpose for which it was obtained"} />
      <Paragraph
        text={
          "Or a secondary purpose that is directly related to the primary purpose"
        }
      />
      <Paragraph
        text={"With your consent; or where required or authorised by law"}
      />

      <HText text={"Third Parties"} />
      <Paragraph
        text={
          "Where reasonable and practicable to do so, we will collect your Personal Information only from you. However, in some circumstances we may be provided with information by third parties. In such a case we will take reasonable steps to ensure that you are made aware of the information provided to us by the third party."
        }
      />
      <HText text={"Disclosure of Personal Information"} />
      <Paragraph
        text={
          "Your Personal Information may be disclosed in a number of circumstances including the following"
        }
      />
      <Paragraph
        text={"Third parties where you consent to the use or disclosure;"}
      />
      <Paragraph text={"Where required or authorised by law"} />

      <HText text={"Privacy Policy Complaints and Enquiries"} />
      <Paragraph
        text={
          "If you have any queries or complaints about our Privacy Policy please contact us at"
        }
      />
      <Paragraph text={"renanamass@gmail.com"} />
      <Paragraph text={"0481725328"} />
    </div>
  );
};
